/* tslint:disable */
export class LocaleKeys {
    constructor(private translate: Function) {
        //
    }

    public THANK_YOU_MSG = (options: { username: any }) => this.translate('THANK_YOU_MSG', options) /* THANKS FOR SHOPPING, {{username}}! */;
    public THANK_YOU_MSG_WITHOUT_NAME = () => this.translate('THANK_YOU_MSG_WITHOUT_NAME') /* THANKS FOR SHOPPING! */;
    public LIKE_YOUR_STYLE_TITLE = () => this.translate('LIKE_YOUR_STYLE_TITLE') /* HOPE TO SEE YOU AGAIN SOON. */;
    public SUMMARY_TITLE = () => this.translate('SUMMARY_TITLE') /* SUMMARY */;
    public ORDER_NUMBER_TITLE = () => this.translate('ORDER_NUMBER_TITLE') /* Your order number: */;
    public TOTAL_COST_TITLE = () => this.translate('TOTAL_COST_TITLE') /* Total cost: */;
    public SHIPPING_TO_TITLE = () => this.translate('SHIPPING_TO_TITLE') /* Shipping to: */;
    public SEND_EMAIL_TEXT = () => this.translate('SEND_EMAIL_TEXT') /*   */;
    public CONTINUE_SHOPPING_BUTTON = () => this.translate('CONTINUE_SHOPPING_BUTTON') /* Continue Shopping */;
    public SHIPPING_TO = (options: { city: any, state: any, zipCode: any, country: any }) => this.translate('SHIPPING_TO', options) /* {{city}}, {{state}} {{zipCode}}, {{country}} */;
    public thankYou = {
        TITLE: () => this.translate('thankYou.TITLE') /* Thank you for your order. */,
        TITLE_WITH_NAME: (options: { fullName: any }) => this.translate('thankYou.TITLE_WITH_NAME', options) /* Thank you for your order, {{fullName}}. */,
        SUBTITLE: () => this.translate('thankYou.SUBTITLE') /* You'll receive an email confirmation soon. */,
        ORDER_NUMBER_LABEL: () => this.translate('thankYou.ORDER_NUMBER_LABEL') /* Order No: */,
        TOTAL_LABEL: () => this.translate('thankYou.TOTAL_LABEL') /* Total: */,
        OFFLINE_PAYMENT_LABEL: () => this.translate('thankYou.OFFLINE_PAYMENT_LABEL') /* Offline Payment */,
        STORE_PICKUP_LABEL: () => this.translate('thankYou.STORE_PICKUP_LABEL') /* Pickup */,
        STORE_PICKUP_TIME: (options: { deliveryTime: any }) => this.translate('thankYou.STORE_PICKUP_TIME', options) /* Ready for pickup: {{deliveryTime}} */,
        SHIPPING_LABEL: () => this.translate('thankYou.SHIPPING_LABEL') /* Shipping to: */,
        DELIVERY_ADDRESS: (options: { addressLine: any, city: any, state: any, zipCode: any, country: any }) => this.translate('thankYou.DELIVERY_ADDRESS', options) /* {{addressLine}}
{{city}}, {{state}} {{zipCode}}
{{country}} */,
        DELIVERY_ADDRESS_FULL: (options: { fullName: any, company: any, addressLine: any, city: any, state: any, zipCode: any, country: any, phone: any }) => this.translate('thankYou.DELIVERY_ADDRESS_FULL', options) /* {{fullName}}
{{company}}
{{addressLine}}
{{city}}, {{state}} {{zipCode}}
{{country}}
{{phone}} */,
        DELIVERY_ADDRESS_FULL_INCLUDING_ADDRESS_LINE_2: (options: { fullName: any, company: any, addressLine: any, addressLine2: any, city: any, state: any, zipCode: any, country: any, phone: any }) => this.translate('thankYou.DELIVERY_ADDRESS_FULL_INCLUDING_ADDRESS_LINE_2', options) /* {{fullName}}
{{company}}
{{addressLine}}, {{addressLine2}}
{{city}}, {{state}} {{zipCode}}
{{country}}
{{phone}} */,
        DOWNLOAD_LINKS_LABEL: () => this.translate('thankYou.DOWNLOAD_LINKS_LABEL') /* Your Downloads: */,
        DOWNLOAD_LINK_LABEL: () => this.translate('thankYou.DOWNLOAD_LINK_LABEL') /* Download */,
        FOOTER_CONFIRMATION_TEXT: () => this.translate('thankYou.FOOTER_CONFIRMATION_TEXT') /* Keep an eye on your inbox - a confirmation email is on its way... */,
        FOOTER_DOWNLOADS_CONFIRMATION_TEXT: () => this.translate('thankYou.FOOTER_DOWNLOADS_CONFIRMATION_TEXT') /* Downloads available for 30 days. And keep an eye on your inbox - we have also sent them by email... */,
        FOOTER_LINK: () => this.translate('thankYou.FOOTER_LINK') /* Continue Shopping */,
        LOADING_TITLE: () => this.translate('thankYou.LOADING_TITLE') /* Processing your payment... */,
        PENDING_ORDER_MESSAGE: (options: { buyerMail: any }) => this.translate('thankYou.PENDING_ORDER_MESSAGE', options) /* Your order is being processed.
We will notify you once it has been approved by email to {{buyerMail}}. */,
        DOWNLOAD_LINKS_ERROR: () => this.translate('thankYou.DOWNLOAD_LINKS_ERROR') /* Download links are currently not available. */,
        DOWNLOAD_LINKS_ERROR_CONTINUED: () => this.translate('thankYou.DOWNLOAD_LINKS_ERROR_CONTINUED') /* You’ll receive an email confirmation soon with the download links to your products. */,
    };
    public THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR = (options: { frequencyUnitSingular: any }) => this.translate('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', options) /* 1 {{frequencyUnitSingular}} subscription */;
    public THANK_YOU_PAGE_SUBSCRIPTION_PLAN_LABEL = () => this.translate('THANK_YOU_PAGE_SUBSCRIPTION_PLAN_LABEL') /* Subscription: */;
    public THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL = (options: { numberOfFrequencyUnits: any, frequencyUnitSingular: any }) => this.translate('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', options) /* {{numberOfFrequencyUnits}} {{frequencyUnitSingular}} subscription */;
    public THANK_YOU_PAGE_SUBSCRIPTION_TITLE = (options: { fullName: any }) => this.translate('THANK_YOU_PAGE_SUBSCRIPTION_TITLE', options) /* Thank you for subscribing, {{fullName}}! */;
    public THANK_YOU_PAGE_PLAN_FREQUENCY_DESCRIPTION = (options: { price: any, frequencyUnitSingular: any }) => this.translate('THANK_YOU_PAGE_PLAN_FREQUENCY_DESCRIPTION', options) /* {{price}} / {{frequencyUnitSingular}} */;
    public THANK_YOU_PAGE_PLAN_MONTHS = () => this.translate('THANK_YOU_PAGE_PLAN_MONTHS') /* month */;
    public THANK_YOU_PAGE_PLAN_YEAR = () => this.translate('THANK_YOU_PAGE_PLAN_YEAR') /* year */;
    public THANK_YOU_PAGE_PLAN_WEEK = () => this.translate('THANK_YOU_PAGE_PLAN_WEEK') /* week */;
    public THANK_YOU_PAGE_PLAN_WEEKS = () => this.translate('THANK_YOU_PAGE_PLAN_WEEKS') /* week */;
    public THANK_YOU_PAGE_PLAN_MONTH = () => this.translate('THANK_YOU_PAGE_PLAN_MONTH') /* month */;
    public THANK_YOU_PAGE_PLAN_YEARS = () => this.translate('THANK_YOU_PAGE_PLAN_YEARS') /* year */;
    public THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_AUTORENEW = () => this.translate('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_AUTORENEW') /* Auto-renew until canceled */;
    public thankYouPage = {
        shipping: {
            label: () => this.translate('thankYouPage.shipping.label') /* Shipping */,
        },
        paymentMethod: {
            label: () => this.translate('thankYouPage.paymentMethod.label') /* Payment method */,
            multipleMethods: {
                label: (options: { paymentMethod1: any, paymentMethod2: any }) => this.translate('thankYouPage.paymentMethod.multipleMethods.label', options) /* {{paymentMethod1}}, {{paymentMethod2}} */,
            },
        },
        subtitle: {
            orderNumber: () => this.translate('thankYouPage.subtitle.orderNumber') /* Order number:  */,
            orderConfirmation: () => this.translate('thankYouPage.subtitle.orderConfirmation') /* You'll receive a confirmation email soon. */,
        },
        quantity: {
            label: () => this.translate('thankYouPage.quantity.label') /* Qty: */,
        },
        total: {
            label: () => this.translate('thankYouPage.total.label') /* Total */,
        },
        title: {
            withName: (options: { name: any }) => this.translate('thankYouPage.title.withName', options) /* Thank you, {{name}} */,
            withoutName: () => this.translate('thankYouPage.title.withoutName') /* Thank you! */,
        },
        customerNote: {
            previewText: () => this.translate('thankYouPage.customerNote.previewText') /* Your customer's note will show here.  */,
            label: () => this.translate('thankYouPage.customerNote.label') /* Note */,
        },
        giftCard: {
            label: () => this.translate('thankYouPage.giftCard.label') /* Gift card */,
        },
        tax: {
            label: () => this.translate('thankYouPage.tax.label') /* Tax */,
        },
        billingInfo: {
            label: () => this.translate('thankYouPage.billingInfo.label') /* Billing address */,
        },
        back: {
            footerLink: () => this.translate('thankYouPage.back.footerLink') /* Back */,
        },
        subtotal: {
            label: () => this.translate('thankYouPage.subtotal.label') /* Subtotal */,
        },
        promoCode: {
            label: (options: { couponCode: any }) => this.translate('thankYouPage.promoCode.label', options) /* Promo code: {{couponCode}} */,
        },
        moreServices: {
            footerLink: () => this.translate('thankYouPage.moreServices.footerLink') /* Check Out More Services */,
        },
        errorPage: {
            error: {
                title: () => this.translate('thankYouPage.errorPage.error.title') /* This page can't be accessed */,
                body: () => this.translate('thankYouPage.errorPage.error.body') /* Make sure you’re logged in to the right account, or check the URL and try again. */,
            },
        },
        shippingAddress: {
            label: () => this.translate('thankYouPage.shippingAddress.label') /* Shipping address */,
        },
        pendingOrder: {
            title: {
                withName: (options: { name: any }) => this.translate('thankYouPage.pendingOrder.title.withName', options) /* Thank you, {{name}} */,
            },
            body: (options: { buyerEmail: any }) => this.translate('thankYouPage.pendingOrder.body', options) /* Your order is being processed. We'll send an order confirmation to {{buyerEmail}} if it's approved. */,
        },
        addressFormat: {
            line1: {
                withoutSubdivision: (options: { addressLine1: any, city: any, zipCode: any, country: any }) => this.translate('thankYouPage.addressFormat.line1.withoutSubdivision', options) /* {{addressLine1}}
{{city}}, {{zipCode}}, {{country}} */,
                withSubdivision: (options: { addressLine1: any, city: any, subdivision: any, zipCode: any, country: any }) => this.translate('thankYouPage.addressFormat.line1.withSubdivision', options) /* {{addressLine1}}
{{city}}, {{subdivision}} {{zipCode}}, {{country}} */,
            },
            line2: {
                withoutSubdivision: (options: { addressLine1: any, addressLine2: any, city: any, zipCode: any, country: any }) => this.translate('thankYouPage.addressFormat.line2.withoutSubdivision', options) /* {{addressLine1}}, {{addressLine2}}
{{city}}, {{zipCode}}, {{country}} */,
                withSubdivision: (options: { addressLine1: any, addressLine2: any, city: any, subdivision: any, zipCode: any, country: any }) => this.translate('thankYouPage.addressFormat.line2.withSubdivision', options) /* {{addressLine1}}, {{addressLine2}}
{{city}}, {{subdivision}} {{zipCode}}, {{country}} */,
            },
        },
        deliveryMethod: {
            free: {
                label: () => this.translate('thankYouPage.deliveryMethod.free.label') /* Free */,
            },
        },
        delivery: {
            label: () => this.translate('thankYouPage.delivery.label') /* Delivery */,
        },
        pickup: {
            label: () => this.translate('thankYouPage.pickup.label') /* Pickup */,
        },
        salesTax: {
            label: () => this.translate('thankYouPage.salesTax.label') /* Sales tax */,
        },
        vat: {
            label: () => this.translate('thankYouPage.vat.label') /* VAT */,
        },
        paid: {
            label: () => this.translate('thankYouPage.paid.label') /* Paid */,
        },
        dueLater: {
            label: () => this.translate('thankYouPage.dueLater.label') /* Due later */,
        },
        taxAmount: {
            defaultText: () => this.translate('thankYouPage.taxAmount.defaultText') /* 0.00 */,
        },
        subtotalAmount: {
            defaultText: () => this.translate('thankYouPage.subtotalAmount.defaultText') /* 10.00 */,
        },
        pickupAddress: {
            label: () => this.translate('thankYouPage.pickupAddress.label') /* Pickup address */,
        },
        productOption1: {
            defaultText: () => this.translate('thankYouPage.productOption1.defaultText') /* Product option 1 */,
        },
        customerName: {
            defaultText: () => this.translate('thankYouPage.customerName.defaultText') /* Customer Name */,
        },
        totalAmount: {
            defaultText: () => this.translate('thankYouPage.totalAmount.defaultText') /* 10.00 */,
        },
        productOption3: {
            defaultText: () => this.translate('thankYouPage.productOption3.defaultText') /* Product option 3 */,
        },
        productPrice: {
            defaultText: () => this.translate('thankYouPage.productPrice.defaultText') /* 10.00 */,
        },
        productName: {
            defaultText: () => this.translate('thankYouPage.productName.defaultText') /* Product Name */,
        },
        productOption2: {
            defaultText: () => this.translate('thankYouPage.productOption2.defaultText') /* Product option 2 */,
        },
        address: {
            defaultText: () => this.translate('thankYouPage.address.defaultText') /* Street, City, State
Zip Code, Country */,
        },
        phoneNumber: {
            defaultText: () => this.translate('thankYouPage.phoneNumber.defaultText') /* Phone number */,
        },
        continueBrowsing: {
            footerLink: () => this.translate('thankYouPage.continueBrowsing.footerLink') /* Continue Browsing */,
        },
        designTab: {
            cardBackgroundlabel: () => this.translate('thankYouPage.designTab.cardBackgroundlabel') /* Cards background opacity & color */,
            pageBackgroundlabel: () => this.translate('thankYouPage.designTab.pageBackgroundlabel') /* Page background opacity & color */,
        },
    };
    public cthank_you_page_plan_frequency_description_2_years_label = () => this.translate('cthank_you_page_plan_frequency_description_2_years_label') /* every 2 years */;
    public thank_you_page_plan_frequency_description_year_singular_label = () => this.translate('thank_you_page_plan_frequency_description_year_singular_label') /* per year */;
    public thank_you_page_plan_frequency_description_months_plural_label = (options: { numberOfFrequencyUnits: any }) => this.translate('thank_you_page_plan_frequency_description_months_plural_label', options) /* every {{numberOfFrequencyUnits}} months */;
    public thank_you_page_plan_frequency_description_month_singular_label = () => this.translate('thank_you_page_plan_frequency_description_month_singular_label') /* per month */;
    public thank_you_page_plan_frequency_description_years_plural_label = (options: { numberOfFrequencyUnits: any }) => this.translate('thank_you_page_plan_frequency_description_years_plural_label', options) /* every {{numberOfFrequencyUnits}} years */;
    public thank_you_page_plan_frequency_description_2_weeks_label = () => this.translate('thank_you_page_plan_frequency_description_2_weeks_label') /* every 2 weeks */;
    public thank_you_page_plan_frequency_description_2_months_label = () => this.translate('thank_you_page_plan_frequency_description_2_months_label') /* every 2 months */;
    public thank_you_page_plan_frequency_description_weeks_plural_label = (options: { numberOfFrequencyUnits: any }) => this.translate('thank_you_page_plan_frequency_description_weeks_plural_label', options) /* every {{numberOfFrequencyUnits}} weeks */;
    public thank_you_page_plan_frequency_description_week_singular_label = () => this.translate('thank_you_page_plan_frequency_description_week_singular_label') /* per week */;
}
/* tslint:enable */
